import React, { Component } from 'react';
import Portfolio from './portfolio';

class Home extends Component {
    render(){
        return (
            <Portfolio></Portfolio>
        );
    }
}

export default Home;